import React, { Component } from 'react';
import '../Register.css'
import AccountHeader from './AccountHeader';
import { Link,Redirect,} from 'react-router-dom'
import { 
    post_data_token,
    LOGIN_CHANGE_PASSWORD,
} from '../../config/network.js'
import {Button} from 'reactstrap'
import { showErrorMessage,  ModalBox, openSuccessBox } from '../MessageBox';
import {FormattedMessage} from 'react-intl';


class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.vid = ''
        this.state = {
            phone:'',
            oldpsw:'',
            psw:'',
            psw2:'',
            oldpsw_err:'',
            phone_err:'',
            psw_err:'',
            psw2_err:'',
            redict:false,
        };
        this.handleChange = this.handleChange.bind(this);        
        this.handleSubmit = this.handleSubmit.bind(this);        
        this.check = this.check.bind(this);        
        this.handleBlur = this.handleBlur.bind(this);        
         
    }
    
    check(name,value){
        if(name==='phone'&&!value.match(/^\d{10,11}$/)){
            this.setState({phone_err:window.appLocale.messages['page.user.phone.err']||'Please input 10 digit phone number'});
        }
        else if(name==='oldpsw'&&value===''){
            this.setState({oldpsw_err:window.appLocale.messages['page.user.oldpassword.err']||'Please input old password'})
        }
        else if(name==='psw'&&value.length<6){
            this.setState({psw_err:window.appLocale.messages['page.user.password.err.length']||'Please input at least 6 digit password'});
        }
        else if(name==='psw2'&&value !== this.state.psw){
            this.setState({psw2_err:window.appLocale.messages['page.user.password.err.notmatch']||'Please input the same password'});
        }
        else{
            this.setState({[name+'_err']:''})
        }
    }
    handleBlur(name){
        let value =this.state[name];
        this.check(name,value);
    }
    handleChange(event){ 
        let name = event.target.name;
        let errName = name+'_err';
        let value = event.target.value
        this.setState({[name]:value});
        if(this.state[errName]!=='')
            this.check(name,value);
    }
    handleSubmit(event){
        if(!this.canSubmit()) {
            showErrorMessage.call(this,window.appLocale.messages['page.changepassword.submit.checkerr']||'Please input all fields.');
            return;
        }
        post_data_token(LOGIN_CHANGE_PASSWORD,{
            oldPsd:this.state.oldpsw,
            psd1:this.state.psw,
            psd2:this.state.psw2,
        }).then(data=>{
            openSuccessBox.call(this,{
                content:window.appLocale.messages['page.changepassword.submit.success']||'Change password success,please relogin.',
                onOK:()=>{
                    localStorage.clear();
                    this.setState({redict:true,redictTo:'/login'});}
            });
            
        }).catch(error=>{
            showErrorMessage.call(this,window.appLocale.messages['page.changepassword.submit.err']||'Change password error:'+error);
        })
        event.preventDefault();
        
    }
    renderLine(name,placeholder,type,icon){
        let errName = name+'_err'
        return(
            <div>
            <div className="input-container">
                <i className={'fa '+icon+' icon'}></i>
                <input className="input-field" type={type} placeholder={placeholder} name={name} onChange={this.handleChange} onBlur={()=>this.handleBlur(name)}/>
            </div>
            { 
            
                this.state[errName]===''?null:
                <p className="error-info" id={errName}>{this.state[errName]}</p>
            }
            </div>
        )
    }
    canSubmit(){
       return this.state.phone!==''
        &&this.state.oldpsw!==''
        &&this.state.psw!==''
        &&this.state.psw2!==''
        &&this.state.phone_err===''
        &&this.state.oldpsw_err===''
        &&this.state.psw_err===''
        &&this.state.psw2_err===''
    }
    render_button(){
        if(this.canSubmit())
         return (<Button color="primary" type="submit" block ><FormattedMessage id="page.sidnav.title.ChangePassword" defaultMessage="Change Password"/></Button>)
        else
         return (<Button color="secondary" type="submit" block ><FormattedMessage id="page.sidnav.title.ChangePassword" defaultMessage="Change Password"/></Button>)
    }
    render() {
        if (this.state.redict) {
            return <Redirect to={this.state.redictTo} />;
          }
        return (
            <div >
                <div className="main">  
                    <AccountHeader page={"ChangePassword"} title={<FormattedMessage id="page.sidnav.title.ChangePassword" defaultMessage="Change Password"/>}/>
                    <div className="main-body">
                <div className='register-form'>
                    <form onSubmit={this.handleSubmit}>
                        <div>
                            <span className="form-title">
                            <FormattedMessage id="page.sidnav.title.ChangePassword" defaultMessage="Change Password"/>                        
                            </span >
                            <Link className="right" to="/login"> <FormattedMessage id="page.login" defaultMessage="Login"/></Link>
                        </div>
                        
                        <hr/>
                        {this.renderLine('phone',window.appLocale.messages['page.user.phone']||'Phone Number','text','fa-phone')}                       
                        {this.renderLine('oldpsw',window.appLocale.messages['page.user.oldpassword']||'Old Password','password','fa-key')}                       
                        {this.renderLine('psw',window.appLocale.messages['page.user.newPassword']||'New Password','password','fa-key')}                       
                        {this.renderLine('psw2',window.appLocale.messages['page.user.newPassword2']||'Re-enter New Password','password','fa-key')}                       
                        {
                        this.render_button()
                        }
                    </form>
                </div>
                </div>
                </div>
                { ModalBox.call(this)}
                
            </div>
        );
    }
}

export default ChangePassword;