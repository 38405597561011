import React, { Component } from 'react';
import './Account.css'
import './APIKeyLine.css'
import { Button } from 'reactstrap';
import {FormattedMessage} from 'react-intl';


class APIKey extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="key-line">
                <div  className="apikeyline-top">
                    <span  className="apikeyline-title">
                    {'APP ID '+this.props.app.app_id}                        
                    </span >
                    <Button color="danger" size="sm" className="apikeyline-title-btn"
                    onClick={()=>this.props.onDelete(this.props.app.app_id)}
                    ><FormattedMessage id="page.appkeys.deleteAppkey" defaultMessage='Delete APP KEY'/></Button>
                    </div>
                <div className="apikeyline-info">
                <table>
                 <tr className="app-key">
                    <td className="lable">APP KEY</td> 
                    <td className="value">{this.props.app.app_key}</td> 
                </tr>
                <tr className="app-secret">
                    <td className="lable">APP SECRET</td> 
                    <td className="value">{this.props.app.app_secret}</td> 
                </tr>
                </table>
                </div>
                
            </div>
        );
    }
}

export default APIKey;