import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';
import Home from './components/Home'
import Pricing from './components/Pricing'
import Api from './components/Api'
import Login from './components/Login'
import Logout from './components/Logout'
import Register from './components/Register'
import ForgotPassword from './components/ForgotPassword'
import Account from './components/Account/Account'
import APIKey from './components/Account/APIKey'
import Usage from './components/Account/Usage'
import Recharge from './components/Account/Recharge'
import Profile from './components/Account/Profile'
import RechargeRecords from './components/Account/RechargeRecords'
import ChangePassword from './components/Account/ChangePassword'
import Support from './components/Account/Support'
import UserAgreement from './components/UserAgreement'

import {
  HashRouter as Router,
  Route,
   
} from 'react-router-dom'
import SendSMS from './components/Account/SendSMS';

class App extends Component {

  renderMainMenu(){
    if(process.env.REACT_APP_LOCATION==='CHINESE'){
      return(
        <div>
          <Route exact path="/" component={Login} />
          <Route path="/pricing" component={Login} />
          <Route path="/api" component={Login} />
        </div>
      )
    } else{
      return(
        <div>
          <Route exact path="/" component={Home} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/api" component={Api} />
        </div>
      )
    }
  }
  render() {
    return (
      <Router>
        <div >
          {this.renderMainMenu()}
          
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/register" component={Register} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/account/dashboard" component={Account} />
          <Route path="/account/apikey" component={APIKey} />
          <Route path="/account/usage" component={Usage} />
          <Route path="/account/recharge" component={Recharge} />
          <Route path="/account/profile" component={Profile} />
          <Route path="/account/changepassword" component={ChangePassword} />
          <Route path="/account/sendsms" component={SendSMS} />
          <Route path="/account/support" component={Support} />
          <Route path="/account/rechargerecords" component={RechargeRecords} />
          <Route path="/UserAgreement" component={UserAgreement} />
        </div>
      </Router>
    );
  }
}

export default App;
