import React, { Component } from 'react';
import './Account.css'
import APIKeyLine from './APIKeyLine'
import AccountHeader from './AccountHeader';
import {
    GET_APP_KEY_URL,
    ADD_APP_KEY_URL,
    DEL_APP_KEY_URL,
    get_data_token,
    post_data_token,
} from '../../config/network'
import { Button } from 'reactstrap';
import { showErrorMessage, ModalBox, openConfirmBox } from '../MessageBox';
import { FormattedMessage } from 'react-intl';


class APIKey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apps:[],
        };
        this.getAppKeys = this.getAppKeys.bind(this);
        this.deleteAppKey = this.deleteAppKey.bind(this);
        this.createAPPKey = this.createAPPKey.bind(this);
    }
    componentDidMount(){
        this.getAppKeys();
    }
    getAppKeys(){
        get_data_token(GET_APP_KEY_URL,{})
        .then(data=>{
            data.App.map(app=>app.key=app.app_id);
            this.setState({apps:data.App});
          
            // console.log("getAppKeys",data.app)
        })
        .catch(err=>{
            this.setState({apps:[]});
        })
    }
    createAPPKey(){
        post_data_token(ADD_APP_KEY_URL,{})
        .then(data=>{
            this.getAppKeys();
        })
        .catch(err=>showErrorMessage.call(this,'Add app key error:'+err))
    }
    deleteAppKey(appId){
        openConfirmBox.call(this,{
            content:<FormattedMessage id="page.appkeys.confirminfo" 
                        defaultMessage="Are your sure you want to delete this key {keyid} ?"
                        values={{
                            keyid:appId,
                        }}
                    />,
            onOK:()=>this.deleteAppKey_i.call(this,appId),
        })
    }
    deleteAppKey_i(appId){
        var self = this;
        // console.log('deleteAppKey',appId);
        post_data_token(DEL_APP_KEY_URL,{
            _appId:appId,
        })
        .then(data=>{
            self.getAppKeys();
        })
        .catch(err=>showErrorMessage.call(this,'Delete app key error:'+err))
    }

    render() {
        return (
            <div >
                <div className="main">  
                    <AccountHeader page={"APIKey"} title="APP Keys"/>
                    <div className="main-body">
                    <Button color="primary"
                    onClick={this.createAPPKey}><FormattedMessage id="page.appkeys.createAppkey" defaultMessage='Create new APP Key'/></Button>
                    
                    {
                        this.state.apps.map(app=>
                            <APIKeyLine app={app} onDelete={this.deleteAppKey}/>
                        )
                    }
                    </div>
                </div>
                { ModalBox.call(this)}
                
            </div>
        );
    }
}

export default APIKey;