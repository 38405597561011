import React, { Component } from 'react';
import './Account.css'

import AmountInput from './AmountInput'
import AccountHeader from './AccountHeader';
import {
    //PAYPAL_CHECKOUT_URL,
    PAYPAL_ADDWALLET_URL,
       post_data_token,
} from '../../config/network'
import { Redirect, } from 'react-router-dom'
import { ModalBox,  showErrorMessage, openSuccessBox } from '../MessageBox'
import { PayPalButton } from "react-paypal-button-v2"
// import {Button} from 'reactstrap'



class Recharge extends Component {
    constructor(props) {
        super(props);
        // this.instance={};
        this.state = {
            // clientToken:'',
            amount: 10,
            redict: false,
        };
        this.onAmountChange = this.onAmountChange.bind(this);
    }
    onAmountChange(event) {
        this.setState({ amount: event.target.value });
    }

    render_paypalbutton()
    {
        let amout=this.state.amount;
        if(amout<5)
            return;
        return (
             
                <PayPalButton
                    amount= {this.state.amount}
                    // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                    onSuccess={(details, data) => {
                        //alert("Transaction completed by " + details.payer.name.given_name);

                        // OPTIONAL: Call your server to save the transaction
                        // return fetch("/paypal-transaction-complete", {
                        //     method: "post",
                        //     body: JSON.stringify({
                        //         orderId: data.orderID
                        //     })
                        // });

                        // following api needs to change to write amount to the wallet
                        post_data_token(PAYPAL_ADDWALLET_URL, {
                           
                            amount: this.state.amount,
                        }).then(data => {
                            openSuccessBox.call(this,
                                {
                                    content: 'Recharge Successfully.',
                                    onOK: () => { this.setState({ redict: true, redictTo: '/account/dashboard' }); }
                                }
                            );
                
                        })
                            //.catch(err=>openErrorBox.call(this,[{content:err}]));
                            .catch(err => showErrorMessage.call(this, err.msg));
                    }}
                    options={{
                        // clientId: "AT992SDT3KYvQXgDheUY1dPBE2DWPXoFIsxJ9RoKTvOfUFwQQxijbQmufAnFniCaaklCX3emj4yj5RYy"
                        clientId: "ASb6M74ScVSYyP__96ThnQFfAYs7JLp0hoM-m6dVDjJedEX6Oo3PllZRfWC1btqSX7Um-LubmOaLuT-k"
                    }}
                   />

        );
    }
    render_paypal() {
       
        return (
            <div>
                <AmountInput value={this.state.amount} onChange={this.onAmountChange} />
              
                {this.render_paypalbutton()}

            </div>
        );
    }


render() {
    if (this.state.redict) {
        return <Redirect to={this.state.redictTo} />;
    }
    return (
        <div >
            <div className="main">
                <AccountHeader page={"Recharge"} title="Recharge" />
                <div className="main-body">
                    {
                        this.render_paypal()
                    }
                </div>
                {ModalBox.call(this)}

            </div>

        </div>
    );
}

}

export default Recharge;