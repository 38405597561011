import React, { Component } from 'react';
import './Account.css'
import DashboardCard from './DashboardCard'
import AccountHeader from './AccountHeader';
import {GET_USER_STAT_URL,WALLET_BALANCE_URL,get_data_token} from '../../config/network'
import {showErrorMessage, ModalBox} from '../MessageBox'
import { FormattedMessage,FormattedNumber } from 'react-intl';

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SentSMSCurMonth:0,
            SentSMSTotal:0,
            Balance:0,
        };
    }
    componentDidMount(){
        if(localStorage.getItem('accessToken')==null){
            this.setState({reload:true});
            return;
        }
        
        get_data_token(WALLET_BALANCE_URL,{})
        .then(data=>{
            this.setState({
                Balance:data.balance,
            })
        })
        .catch(err=>showErrorMessage.call(this,'Get balance error:'+err))

        if(localStorage.getItem('accessToken')==null){
            this.setState({reload:true});
            return;
        }

        get_data_token(GET_USER_STAT_URL,{})
        .then(data=>{
            this.setState({
                SentSMSCurMonth:data.SentSMSCurMonth,
                SentSMSTotal:data.getSentSMSTotal,
            })
        })
        .catch(err=>showErrorMessage.call(this,'Get user statistics error:'+err))
    }
    getCurCost(){
        let smsCount = this.state.SentSMSCurMonth;
        let numbers = [0,10000,100000,1000000];
        let prices =  [0.006,0.005,0.004,0.003];
        let amounts = [0,60,510,4110];
        let len = amounts.length;
        for(var i = len-1;i>=0;i--) 
            if(smsCount>=numbers[i]) break;
        return amounts[i] + (smsCount-numbers[i])*prices[i];
    }
    getCurMonthSMS(){
        let curMonthCost = this.getCurCost();
        let money = this.state.Balance + curMonthCost;
        let numbers = [0,10000,100000,1000000];
        let prices =  [0.006,0.005,0.004,0.003];
        let amounts = [0,60,510,4110];
        let len = amounts.length;
        for(var i = len-1;i>=0;i--) 
            if(money>=amounts[i]) break;
        return numbers[i] + Math.floor((money-amounts[i])/prices[i])-this.state.SentSMSCurMonth;
    }
    getCurrency(amount){
        return (
            <FormattedNumber
            value={amount}
            style={"currency"}
            currency={window.appLocale.formats.money.currency}
            />
        )
    }
    render() {
        return (
            <div >
                    <div className="main">  
                        <AccountHeader page={"Account"} 
                            title={<FormattedMessage id="page.sidnav.title.Dashboart" defaultMessage="Dashboard"/>} 
                        />

                        <div className="main-body">
                            <DashboardCard 
                                title={<FormattedMessage id="page.dashboard.title.balance" defaultMessage="BALANCE"/>} 
                                content={this.getCurrency(this.state.Balance)} 
                                
                                des={
                                    <FormattedMessage id="page.dashboard.balancedes" 
                                    defaultMessage="You can send {count} SMS in this month."
                                    values={{count:this.getCurMonthSMS()}}
                                    />
                                }
                            />
                            <DashboardCard 
                                title={<FormattedMessage id="page.dashboard.title.SMSSentCurMonth" defaultMessage="SMS SENT - THIS MONTH"/>}
                                content={this.state.SentSMSCurMonth} 
                                des={
                                    <FormattedMessage id="page.dashboard.SMSSentCurMonth.des" 
                                    defaultMessage="Your cost is {count}  in this month."
                                    values={{count:this.getCurrency(this.getCurCost())}}
                                    />
                                }
                            />
                            <DashboardCard 
                                title={<FormattedMessage id="page.dashboard.title.SMSSentTotal" defaultMessage="SMS SENT - TOTAL"/>}
                                content={this.state.SentSMSTotal} 
                                des={
                                    <FormattedMessage id="page.dashboard.SMSSentTotal.des" 
                                    defaultMessage="You have sent {count} SMS totally."
                                    values={{count:this.state.SentSMSTotal}}
                                    />
                                }
                             />
                        </div>
                        { ModalBox.call(this)}
                        
                    </div>

            </div>
        );
    }
}

export default Account;