/*
import React, { Component } from 'react';
import './Account.css'
import { Col, Button, Form, FormGroup, Label, Input, } from 'reactstrap';
import './Profile.css'
import AccountHeader from './AccountHeader';
import {
    GET_MEMBER_INFO_URL,
    UPDATE_MEMBER_INFO_URL,
    get_data_token,
    post_data_token,
} from '../../config/network'
import { Link, Redirect, } from 'react-router-dom'
import { showErrorMessage, showSuccessMessage, ModalBox } from '../MessageBox';
import {FormattedMessage} from 'react-intl';



class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberId:'',
            phone:'',
            email:'',
            first_name:'',
            last_name:'',
            company:'',
            city:'',
            state:'',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        
    }
    componentDidMount(){
        get_data_token(GET_MEMBER_INFO_URL,{})
        .then(data=>{
            this.setState({
                memberId:data.memberId,
                phone:data.phone,
                email:data.email,
                first_name:data.firstName,
                last_name:data.lastName,
                company:data.company,
                city:data.city,
                state:data.state,
            })
        })
        .catch(err=>{
            showErrorMessage.call(this,'Get member info error,'+err);
        })
    }
    handleSubmit(event) {
       
        post_data_token(UPDATE_MEMBER_INFO_URL,{	
            _first_name:this.state.first_name,		
            _last_name:	this.state.last_name,	
            _email:	this.state.email,	
            _phone:	this.state.phone,	
            _company:this.state.company	,	
            _city:	this.state.city	,
            _state:this.state.state,
        })
        .then(data=>showSuccessMessage.call(this,<FormattedMessage id="page.profile.update.success" defaultMessage="Update success!"/>))
        .catch(err=>showErrorMessage.call(this,'Update error:'+err))
        event.preventDefault();
      }
    handleChange(event) {
        this.setState({[event.target.id] : event.target.value});
      }
    
    render() {
        return (
            <div >
                    <div className="main">  
                         <AccountHeader page='Profile' title={<FormattedMessage id="page.sidnav.title.Profile" defaultMessage="Profile"/>}/>
                        <div className="main-body">
                            <Form className="profile-form" onSubmit={this.handleSubmit}>
        
                                <FormGroup row>
                                    <Label for="phone" sm={3}><FormattedMessage id="page.profile.phone" defaultMessage="Phone"/></Label>
                                    <Col sm={9}>
                                        <Input type="phone" id="phone" value={this.state.phone} 
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="email" sm={3}><FormattedMessage id="page.profile.email" defaultMessage="Email"/></Label>
                                    <Col sm={9}>
                                        <Input type="email" id="email" value={this.state.email} 
                                        onChange={this.handleChange}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="firstname" sm={3}><FormattedMessage id="page.profile.firstname" defaultMessage="First Name"/></Label>
                                    <Col sm={9}>
                                        <Input type="text" id="first_name" value={this.state.first_name} 
                                        onChange={this.handleChange}/>
                                    </Col>
                                </FormGroup>
                                {
                                process.env.REACT_APP_LOCATION==='CHINESE'?null:
                                <FormGroup row>
                                    <Label for="lastname" sm={3}><FormattedMessage id="page.profile.lastname" defaultMessage="Last Name"/></Label>
                                    <Col sm={9}>
                                        <Input type="text" id="last_name" value={this.state.last_name} 
                                        onChange={this.handleChange}/>
                                    </Col>
                                </FormGroup>
                                }
                                <FormGroup row>
                                    <Label for="company" sm={3}><FormattedMessage id="page.profile.company" defaultMessage="Company"/></Label>
                                    <Col sm={9}>
                                        <Input type="text" id="company" value={this.state.company} 
                                        onChange={this.handleChange}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="city" sm={3}><FormattedMessage id="page.profile.city" defaultMessage="City"/></Label>
                                    <Col sm={9}>
                                        <Input type="text" id="city" value={this.state.city} 
                                        onChange={this.handleChange}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="state" sm={3}><FormattedMessage id="page.profile.state" defaultMessage="State"/></Label>
                                    <Col sm={9}>
                                        <Input type="text" id="state" value={this.state.state} 
                                        onChange={this.handleChange}/>
                                    </Col>
                                </FormGroup>
                                
                                <FormGroup  row>
                                <Label for="state" sm={3}></Label>
                                <Col sm={{ size: 9}}>
                                    <Button color="primary"  type="submit" block><FormattedMessage id="page.submit" defaultMessage="Submit"/></Button>
                                </Col>
                                </FormGroup>
                            </Form>
                            { ModalBox.call(this)}
                            
                        </div>
                    </div>
            </div>
        );
    }
}
export default Profile;
*/

    import React, { Component } from 'react';
    import './Account.css'
    import './Profile.css'
    import 'antd/dist/antd.css';
    import AccountHeader from './AccountHeader';
    import {
        Form,
        Input,
        Tooltip,
        Icon,
        Button,
        message,
        Modal,
      } from 'antd';
    import {
        GET_MEMBER_INFO_URL,
        UPDATE_MEMBER_INFO_URL,
        get_data_token,
        post_data_token,
        VCODE_SEND_URL,
        EMAIL_VERIFY_URL
    
    } from '../../config/network.js'
    
    import { Link, Redirect, } from 'react-router-dom'
    import { showErrorMessage, ModalBox, showSuccessMessage } from '../MessageBox';
    import './DashboardCard.css'
    
    class Profile extends Component {
    
        constructor(props) {
            let redict_init = false;
            let redictTo_init = null;
            super(props);
            this.state = {
                memberId:'',
                phone:'',
                email:'',
                first_name:'',
                last_name:'',
                company:'',
                city:'',
                state:'',
                redict: redict_init,
                redictTo: redictTo_init,
                visible: false,
                loading1: false,
                vcode_got: '',
                vcode : ''
            }
           this.handleSubmit = this.handleSubmit.bind(this);
           this.rendersendcode = this.rendersendcode.bind(this);
           this.sendVCode = this.sendVCode.bind(this);
           this.handleVcodeSubmit = this.handleVcodeSubmit.bind(this);
           this.handleVcodeChange = this.handleVcodeChange.bind(this);
    
        }
        showModal = () => {
            this.setState({
                email : this.props.form.getFieldValue('email'),
                visible: true,
            });
        };
    
        handleOk = () => {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false, visible: false });
            }, 3000);
        };
    
        componentDidMount() {
            let isemail = localStorage.getItem('isEmailVerified');
            if (isemail === '1') { this.setState({ emailverified: true }) };
            get_data_token(GET_MEMBER_INFO_URL,{})
            .then(data=>{
                this.setState({
                    memberId:data.memberId,
                    phone:data.phone,
                    email:data.email,
                    first_name:data.firstName,
                    last_name:data.lastName,
                    company:data.company,
                    city:data.city,
                    state:data.state,
                })
            })
            .catch(err=>{
                showErrorMessage.call(this,'Get member info error,'+err);
            })
    

            let isprofile = localStorage.getItem('isProfileCompleted');
    
            if (isprofile === '1') { }
            else
                showErrorMessage.call(this, 'Please complete your profile');
        }
    
        handleSubmit(event) {
    
            event.preventDefault();
    
            this.props.form.validateFieldsAndScroll((err, values) => {
                let isemail = localStorage.getItem('isEmailVerified');
                if (!err) {
                  if (isemail === '1') { 
                  
                    post_data_token(UPDATE_MEMBER_INFO_URL,{	
                        _first_name:values.first_name,		
                        _last_name:	values.last_name,	
                        _email:	this.state.email,	
                        _phone:	this.state.phone,	
                        _company:values.company	,	
                        _city:	values.city	,
                        _state: values.state,
                    })
                    .then(data => message.success("Update Success！"),
                    localStorage.setItem('isProfileCompleted', 1),
    

                    setTimeout(() => {
                        this.setState({ redict: true, redictTo: '/account/dashboard' })
                      }, 1500)
                )
                .catch(msg=> showErrorMessage.call(this, 'Update error:' + msg))
    
                }
                else
                {
                    post_data_token(UPDATE_MEMBER_INFO_URL,{	
                        _first_name:values.first_name,		
                        _last_name:	values.last_name,	
                        _email:	this.state.email,	
                        _phone:	this.state.phone,	
                        _company:values.company	,	
                        _city:	values.city	,
                        _state: values.state,
                    })
                    .then(data => message.success("Update Success！"),
                    localStorage.setItem('isProfileCompleted', 1),
    

                    setTimeout(() => {
                        this.setState({ redict: true, redictTo: '/account/dashboard' })
                      }, 1500)
                )
                .catch(msg=> showErrorMessage.call(this, 'Update error:' + msg))
                }
                //
              }

            });
    
        }
    
        handleVcodeChange(event) {
            
                let name = event.target.name;
                let value = event.target.value
                this.setState({ [name]: value,  ModalText: ''});

            
        }
    
        handleVcodeSubmit(){
            let vcode_got = this.state.vcode_got;
            
            if (vcode_got === undefined || vcode_got === null || vcode_got === '') {
                this.setState({
                    ModalText: 'please click Get Code button again' ,
                    // loadcancel: false,
                })
                return;
            }
          
            if (vcode_got.toString() !==this.state.vcode ) {
                this.setState({
                    ModalText: 'please input right code' ,
                   })
                return;
            }
            else {
                this.setState({
                    ModalText: 'Email verification is successful' ,
                   
                })
                post_data_token(EMAIL_VERIFY_URL, {
                   // email: this.state.email,
                   vcode:this.state.vcode,
                    
                })
                    .then(data => message.success("Update Success！"),
                        localStorage.setItem('isEmailVerified', 1),
                       this.setState ({emailverified: 1}) )
                    .catch(err => showErrorMessage.call(this, 'Update error:' + err))
    
                setTimeout(() => {
                    this.setState({ visible: false,})
                  }, 1500);
                
                return;
            }
    
          }
        handleCancel = () => {
            // console.log('Clicked cancel button');
            this.setState({
                visible:false
            });
        };
    
        rendersendcode() {
           
            return (
                <Modal
                    title="Input the verification code "
                    visible={this.state.visible}
                    onOk={this.handleVcodeSubmit}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                          Return
                        </Button>,
                        <Button key="submit" type="primary"  onClick={this.handleVcodeSubmit}>
                          Submit
                        </Button>,
                        <Button
                        type="secondary"
                          onClick={this.sendVCode}
                        >
                         {this.state.loading1 && <span>Waiting</span>}
                        {!this.state.loading1 && <span>Get Code</span>}
                        </Button>,
                      ]}
                >
                    <Form >
                        <Form.Item label="Verification Code">
                            <Input type="number" placeholder={window.appLocale.messages['page.user.vcode'] || "Verifiation code"} name="vcode" onChange={this.handleVcodeChange} />
                        </Form.Item>
                    </Form>
                    <p>{this.state.ModalText}</p>
                </Modal>
            )
        }
    
    
        sendVCode() {
            this.setState({ loading1: true });
    
            post_data_token(VCODE_SEND_URL, { email: this.state.email })
                .then((data) => {
                    this.setState({ vcode_got: data.vcode });
                    showSuccessMessage.call(this, window.appLocale.messages['page.user.vcode.send.success'] || "Sent vcode success!");
                    this.setState({ loading1: false });
                })
                .catch((error) => {
                    showErrorMessage.call(this, this, window.appLocale.messages['page.user.vcode.send.err'] || "Sent vcode fail," + error);
                    // console.error(error);
                    this.setState({ loading1: false });
                });
        }

        render() {
            const { visible, loading } = this.state;
            const { getFieldDecorator } = this.props.form;
            let states = this.state.statenames;
 
            const formItemLayout = {
                labelCol: {
                    xs: { span: 16 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 20 },
                    sm: { span: 12 },
                },
            };
            const tailFormItemLayout = {
                wrapperCol: {
                    xs: {
                        span: 16,
                        offset: 8,
                    },
                    sm: {
                        span: 16,
                        offset: 8,
                    },
                },
            };
            if (this.state.redict) {
                return <Redirect to={this.state.redictTo} />;
            }
            return (
                <div>
                    <div className="main">
                        <AccountHeader page={"Profile"} title="Profile" />
                        <div className="main-body">
                            <div class="dcard">
    
                                <div Class="dcard-titile">
                                 Verification Info
                                </div>
                                <div className="dcard-body">
                                   
                                    <p>
                                        Email:        {this.state.email}
                                    </p>
                                   
                                    <p>
    
                                        {!this.state.emailverified && <Button type="primary" style={{ marginRight: 3 }} onClick={this.showModal}><span>Please verify email</span> </Button>  }
                                        
                                       {this.rendersendcode() }
                                        {this.state.emailverified && <Button type="secondary" style={{ marginRight: 3 }} disabled><span>Verified</span> </Button>}
    

                                        <Link to="/account/ChangePassword" Class="pull-middle title-link"><Button type="primary">
                                            Change Password
                                        </Button>
                                        </Link>
                                    </p>
                                </div>
    
    
                            </div>
                            <br /> <br />
                            <div class="dcard">
                                <div Class="dcard-titile">
                                    <h4 Class="left">Personal Information</h4>
                                </div>
                                <div className="dcard-body" align='left'>
    
                                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
    
                                        <Form.Item label="phone">
                                            {getFieldDecorator('phone', {
                                                initialValue: this.state.phone,
                                                //rules: [{ required: true, message: 'Please input your phone!', whitespace: true }],
                                                rules: [{ required: false, message: '', whitespace: true }],
                                            })(<Input />)}
                                        </Form.Item>
                                        <Form.Item label="email">
                                            {getFieldDecorator('email', {
                                                initialValue: this.state.email,
                                                rules: [{ required: true, message: 'Please input email!', whitespace: true }],
                                            })(<Input />)}
                                        </Form.Item>
                                        <Form.Item label="First Name">
                                            {getFieldDecorator('first_name', {
                                                initialValue: this.state.first_name,
                                                rules: [{ required: true, message: 'Please input your firstname!', whitespace: true }],
                                            })(<Input />)}
    
                                        </Form.Item>
                                        <Form.Item label="Last Name">
                                            {getFieldDecorator('last_name', {
                                                initialValue: this.state.last_name,
                                                rules: [{ required: true, message: 'Please input your lastname!', whitespace: true }],
                                            })(<Input />)}
                                        </Form.Item>
                                        <Form.Item label="company">
                                            {getFieldDecorator('company', {
                                                initialValue: this.state.company,
                                                rules: [{ required: false, message: '', whitespace: true }],
                                            })(<Input />)}
                                        </Form.Item>
                                        <Form.Item label="city">
                                            {getFieldDecorator('city', {
                                                initialValue: this.state.city,
                                                rules: [{ required: false, message: '', whitespace: true }],
                                            })(<Input />)}
                                        </Form.Item>
                                        <Form.Item label="state">
                                            {getFieldDecorator('state', {
                                                initialValue: this.state.state,
                                                rules: [{ required: false, message: '', whitespace: true }],
                                            })(<Input />)}
                                        </Form.Item>

                                        <Form.Item {...tailFormItemLayout}>
                                        {!this.state.emailverified && <Button key="submit" type="primary" style={{ marginRight: 3 }}  disabled><span>Please verify email</span> </Button>  }
                                      
                                         {this.state.emailverified && <Button key="submit" type="primary" htmlType="submit" ><span>Submit</span> </Button>}
                                           
                                            
                                        </Form.Item>
                                    </Form>
                                    {ModalBox.call(this)}
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div >
    
    
            );
        }
    }
    export default Form.create({ name: 'profile' })(Profile)