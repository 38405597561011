import React, { Component } from 'react';
import Header from './Header'
import Footer from './Footer'
import './Pricing.css'


class UserAgreement extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className='myContainer'>
                <Header page='UserAgreement'/>
                <section>
                    <div className="mycontainer">
                    <p>&nbsp;</p>
<h1><strong>Terms and Conditions</strong></h1>
<p>&nbsp;</p>
<p>Version Date: 05/01/2021</p>
<p>&nbsp;</p>
<p><strong>TERMS OF SERVICE AGREEMENT</strong></p>

<p>This Terms and Conditions Agreement (&ldquo;Agreement&rdquo;) constitutes a legally binding agreement made between you, whether personally or on behalf of an entity (&ldquo;user&rdquo; or &ldquo;you&rdquo;) and EZ2GoSMS and its affiliated companies (collectively, &ldquo;EZ2GoSMS&rdquo; or &ldquo;we&rdquo; or &ldquo;us&rdquo; or &ldquo;our&rdquo;), concerning your access to and use of the EZ2GoSMS service. You agree that by using this service, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibtied from using the service and you must discontinue the use immediately.</p>

<p>EZ2GoSMS makes no representation that the Service is appropriate or available in other locations other than where it is operated by EZ2GoSMS. The information provided on the Service is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject EZ2GoSMS to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Service from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.&nbsp;</p>

<p>All users who are minors in the jurisdiction in which they reside (generally under the age of 18) are not permitted to register an account to use the EZ2GoSMS Service.</p>
<p>&nbsp;&nbsp;</p>
<p><strong>USER</strong> <strong>REGISTRATION</strong></p>

<p>By using the EZ2GoSMS Services, you must create a EZ2GoSMS account by registering at the EZ2GoSMS Service on website.</p>

<p>By registering your account, you represent and warrant that:</p>

<p>A. all registration information you submit is truthful and accurate;</p>
<p>B. you will maintain the accuracy of such information;</p>
<p>C. you will keep your password confidential and will be responsible for all use of your password and account;</p>
<p>D. you are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use this Service; and</p>
<p>E. your use of the EZ2GoSMS Services does not violate any applicable law or regulation, include local, state, national, and international laws and regulations.</p>

<p>You also agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the Service&rsquo;s registration form and (b) maintain and promptly update registration data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or&nbsp;EZ2GoSMS has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete,&nbsp;EZ2GoSMS has the right to suspend or terminate your account and refuse any and all current or future use of the Service (or any portion thereof).&nbsp;</p>
<p>&nbsp;</p>

<p><strong>USER</strong> <strong>RESPONSIBILITY</strong></p>

<p>By using our service, you should make sure you fully agree on your RESPONSIBILITY listed below:</p>

<p>A. You’re responsible for all use of our services under your account, including your data and the software applications you develop or services you offer;</p>
<p>B. You’ll be responsible for your end users' acts, omissions, and activities;</p>
<p>C. You’ll be responsible for the legality of all of your SMS message you send to your end users. If we find that your information is illegal or possibly illegal, we will terminate the service to you, and all funds in your account will not be refunded</p>
<p>D. You'll prevent unauthorized access to or use of our services;</p>
<p>E. Again, your use of the EZ2GoSMS Services does not violate any applicable law or regulation, include local, state, national, and international laws and regulations.</p>

<p>You also agree to: (a) We have no liability for any unauthorized use under your account. (b) All disputes that might araised relvent to the messages you send will be fully your responsibility. &nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;<strong>PURCHASES; PAYMENT</strong></p>

<p>Certain aspects of the Services are, or may in the future be, provided for a fee or other charge. In the event you choose to use paid aspects of the Services, you agree to pay EZ2GoSMS all charges at the prices then in effect for the products you or other persons using your billing account may purchase, and you authorize EZ2GoSMS to charge your chosen payment provider for any such purchases. You agree to make payment using that selected payment method. If you have ordered a product or service that is subject to recurring charges then you consent to our charging your payment method on a recurring basis, without requiring your prior approval &nbsp;from you for each recurring charge until such time as you cancel the applicable product or service. EZ2GoSMS reserves the right to correct any errors or mistakes in pricing that it makes even if it has already requested or received payment. Sales tax will be added to the sales price of purchases as deemed required by EZ2GoSMS. EZ2GoSMS may change prices at any time. All payments shall be in U.S. dollars.&nbsp;</p>
<p><strong>The charges will not be refundable after purchase</strong></p>
<p>&nbsp;&nbsp;<strong>&nbsp;</strong>&nbsp;</p>
<p><strong>INTELLECTUAL PROPERTY RIGHTS</strong></p>

<p>The content on the Service (&ldquo;EZ2GoSMS Content&rdquo;) and the trademarks, service marks and logos contained therein (&ldquo;Marks&rdquo;) are owned by or licensed to EZ2GoSMS, and are subject to copyright and other intellectual property rights under United States and foreign laws and international conventions. EZ2GoSMS Content, includes, without limitation, all source code, databases, functionality, software, Service designs, audio, video, text, photographs and graphics. All EZ2GoSMS graphics, logos, designs, page headers, button icons, scripts and service names are registered trademarks, common law trademarks or trade dress of EZ2GoSMS in the United States and/or other countries. EZ2GoSMS's trademarks and trade dress may not be used, including as part of trademarks and/or as part of domain names, in connection with any product or service in any manner that is likely to cause confusion and may not be copied, imitated, or used, in whole or in part, without the prior written permission of the EZ2GoSMS.</p>

<p>EZ2GoSMS Content on the Service is provided to you &ldquo;AS IS&rdquo; for your information and personal use only and may not be used, copied, reproduced, aggregated, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of the respective owners. Provided that you are eligible to use the Service, you are granted a limited license to access and use the Service and the EZ2GoSMS Content and to download or print a copy of any portion of the EZ2GoSMS Content to which you have properly gained access solely for your personal, non-commercial use. EZ2GoSMS reserves all rights not expressly granted to you in and to the Service and EZ2GoSMS Content and Marks.</p>
<p>&nbsp;</p>
<p><strong>THIRD PARTY SITES, PRODUCTS AND SERVICES </strong></p>

<p>The Services may contain links to third party websites, applications, products, and services that are offered and/or maintained by others (collectively, "Third Party Services"). Any such links are provided solely as a convenience to you and not as an endorsement by EZ2GoSMS of the Third Party Services.&nbsp; EZ2GoSMS is not responsible for such Third Party Services and does not make any representations or warranties regarding such Third Party Services or the privacy practices of such third parties. If you decide to access such Third Party Services, you do so at your own risk.</p>
<p>&nbsp;<strong>&nbsp;</strong></p>
<p><strong>EZ2GOSMS MANAGEMENT </strong></p>

<p>EZ2GoSMS reserves the right but does not have the obligation to:</p>

<p>A. investigate violations of this Agreement;</p>
<p>B. take appropriate legal action against anyone who, in EZ2GoSMS&rsquo;s sole discretion, violates this Agreement, including without limitation, reporting such user to law enforcement authorities;</p>
<p>C. in EZ2GoSMS&rsquo;s sole discretion and without limitation, refuse, restrict access to or availability of, or disable (to the extent technologically feasible) any user&rsquo;s contribution or any portion thereof that may violate this Agreement or any EZ2GoSMS policy;</p>
<p>D. in&nbsp;EZ2GoSMS&rsquo;s sole discretion and without limitation, notice or liability to suspend or terminates anyone&rsquo;s use of the Service and the Services.</p>
<p>E. otherwise manage the Service and the Services in a manner designed to protect the rights and property of EZ2GoSMS and others and to facilitate the proper functioning of the Service and the Services.</p>
<p>&nbsp;</p>
<p><strong>TERM AND TERMINATION</strong></p>

<p>This Agreement shall remain in full force and effect while you use the Service. You may terminate your use or participation at any time, for any reason, by following the instructions for terminating user accounts in your account settings, if available, or by contacting us.</p>
<p>&nbsp;</p>
<p>WITHOUT LIMITING ANY OTHER PROVISION OF THIS AGREEMENT, EZ2GOSMS RESERVES THE RIGHT TO, IN EZ2GOSMS&rsquo;S SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICE AND THE EZ2GOSMS SERVICES, TO ANY PERSON FOR ANY REASON OR FOR NO REASON AT ALL, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY OR COVENANT CONTAINED IN THIS AGREEMENT, OR OF ANY APPLICABLE LAW OR REGULATION, AND EZ2GOSMS MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICE AND THE EZ2GOSMS SERVICES, DELETE YOUR PROFILE AND ANY CONTENT OR INFORMATION THAT YOU HAVE POSTED AT ANY TIME, WITHOUT WARNING, IN EZ2GOSMS&rsquo;S SOLE DISCRETION.</p>
<p>&nbsp;&nbsp;</p>
<p>If EZ2GoSMS terminates or suspends your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, EZ2GoSMS reserves the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
<p>&nbsp;</p>
<p><strong>MODIFICATIONS</strong></p>

<p>EZ2GoSMS may modify this Agreement from time to time. Any and all changes to this Agreement will be posted on the Service and revisions will be indicated by date. You agree to be bound to any changes to this Agreement when you use the EZ2GoSMS Services after any such modification becomes effective. EZ2GoSMS may also, in its discretion, choose to alert all users with whom it maintains email information of such modifications by means of an email to their most recently provided email address. It is therefore important that you regularly review this Agreement and keep your contact information current in your account settings to ensure you are informed of changes. You agree that you will periodically check the Service for updates to this Agreement and you will read the messages we send you to inform you of any changes. Modifications to this Agreement shall be effective after posting.</p>
<p>&nbsp;</p>
<p><strong>CORRECTIONS</strong></p>

<p>Occasionally there may be information on the Service that contains typographical errors, inaccuracies or omissions that may relate to service descriptions, pricing, availability, and various other information. EZ2GoSMS reserves the right to correct any errors, inaccuracies or omissions and to change or update the information at any time, without prior notice.</p>
<p>&nbsp;</p>
<p><strong>LIMITATIONS OF LIABILITY</strong></p>

<p>IN NO EVENT SHALL EZ2GOSMS OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICE OR EZ2GOSMS SERVICES, EVEN IF EZ2GOSMS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, EZ2GOSMS&rsquo;S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO EZ2GOSMS FOR THE EZ2GOSMS SERVICES DURING THE PERIOD OF 3 PRIOR TO ANY CAUSE OF ACTION ARISING.</p>
<p>&nbsp;</p>
<p>CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS SERVICELY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT SERVICELY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
<p>&nbsp;<strong>&nbsp;</strong></p>
<p><strong>USER DATA</strong></p>

<p>Our Service will maintain certain data that you transfer to the Service for the purpose of the performance of the EZ2GoSMS Services, as well as data relating to your use of the EZ2GoSMS Services. Although we perform regular routine backups of data, you are primarily responsible for all data that you have transferred or that relates to any activity you have undertaken using the EZ2GoSMS Services.&nbsp; You agree that EZ2GoSMS shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against EZ2GoSMS arising from any such loss or corruption of such data.</p>
<p><strong>&nbsp;&nbsp;&nbsp;</strong></p>
<p><strong>MISCELLANEOUS</strong></p>

<p>This Agreement constitutes the entire agreement between you and EZ2GoSMS regarding the use of the EZ2GoSMS Services. The failure of EZ2GoSMS to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision. The section titles in this Agreement are for convenience only and have no legal or contractual effect. This Agreement operates to the fullest extent permissible by law. This Agreement and your account may not be assigned by you without our express written consent. EZ2GoSMS may assign any or all of its rights and obligations to others at any time. EZ2GoSMS shall not be responsible or liable for any loss, damage, delay or failure to act caused by any cause beyond EZ2GoSMS's reasonable control.&nbsp; If any provision or part of a provision of this Agreement is unlawful, void or unenforceable, that provision or part of the provision is deemed severable from this Agreement and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and EZ2GoSMS as a result of this Agreement or use of the Service and EZ2GoSMS Services. Upon EZ2GoSMS&rsquo;s request, you will furnish EZ2GoSMS any documentation, substantiation or releases necessary to verify your compliance with this Agreement. You agree that this Agreement will not be construed against EZ2GoSMS by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of this Agreement and the lack of signing by the parties hereto to execute this Agreement</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>                        

                    </div>
                </section>
                <Footer />
            </div>
            
        );
    }
}

export default UserAgreement;