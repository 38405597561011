import React, { Component } from 'react';
import './ShowCase.css'
import { Link} from 'react-router-dom'


class ShowCase extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <section id="showcase">
                <div className="mycontainer">
                    <h1>Affordable Bulk SMS Service</h1>
                    <p>Use our online SMS Service for SMS marketing, sending SMS verification code or notification to your customer</p>
                    <br/>
                    <Link className="btn1" to="/register"> TRY IT FOR FREE</Link>
                    <p></p>
                </div>
                
            </section>
        );
    }
}

export default ShowCase;