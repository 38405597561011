export default {
  'page.sidnav.title.Dashboart': 'Dashboard',
  'page.sidnav.title.SendSMS': 'Send SMS',
  'page.sidnav.title.AppKeys': 'App Keys',
  'page.sidnav.title.Recharge': 'Recharge',
  'page.sidnav.title.RechargeRecords': 'Recharge Records',
  'page.sidnav.title.Profile': 'Profile',
  'page.sidnav.title.ChangePassword': 'Change Password',
  'page.sidnav.title.Support': 'Support',

  'page.logout': 'Logout',
  'page.login': 'Login',
  'page.submit': 'Submit',
  'page.ok': 'OK',
  'page.cancel': 'Cancel',
  'page.register':'Register',

  'page.dashboard.title.balance': 'Balance',
  'page.dashboard.title.SMSSentCurMonth': 'SMS SENT - THIS MONTH',
  'page.dashboard.title.SMSSentTotal': 'SMS SENT - TOTAL',
  'page.dashboard.balancedes': 'You can send  {count} SMS in this month.',
  'page.dashboard.SMSSentCurMonth.des': 'Your cost is {count} in this month',
  'page.dashboard.SMSSentTotal.des': 'You have sent {count} SMS totally.',

  'page.sendsms.title.selectappkey': 'Slect a App Key to Send SMS',
  'page.sendsms.title.SMSContent': 'SMS Content',
  'page.sendsms.contenttip': '{count1} SMS, {count2} Characters  ({count3}/SMS)',
  'page.sendsms.title.CountryCode': 'Country Code',
  'page.sendsms.title.PhoneNumbers':'Phone Numbers',
  "page.sendsms.title.PhoneNumbers.tip":'You can enter or copy any text containing phone numbers to here, and the phone numbers can be automatically extracted.',

  "page.sendsms.err.selectappkey":'Please create a app key firstly',
  "page.sendsms.err.SMSContent":'Please input SMS content.',
  "page.sendsms.err.PhoneNumbers":'Please input phone numbers.',
  "page.sendsms.parsedes":'Parsed {count1} mobile phone numbers: {count2}',

  "page.sendsms.title.SubmitSMS":'Submitting SMS',  
  "page.sendsms.progressbox.successinfo":'SMS Submit Successfully!',
  "page.sendsms.confirminfo":'You will send to {count1} phone numbers, total {count2} SMS.',

  "page.comfirmbox.title":'Please Confirm',

  "page.appkeys.createAppkey":'Create New App Key',
  "page.appkeys.deleteAppkey":'Delete App Key',
  "page.appkeys.confirminfo":'Are your sure you want to delete this key {keyid} ?',

  "page.profile.email":'Email',  
  "page.profile.phone":'Phone',
  "page.profile.firstname":'firstname',
  "page.profile.lastname":'lastname',
  "page.profile.company":'Company',
  "page.profile.city":'City',
  "page.profile.state":'State',
  "page.profile.update.success":'Update success!',

  "page.user.phone":'Phone',
  "page.user.phone.err":'Please input phone number',
  "page.user.password":'Password',
  "page.user.password2":'Re-enter password',
  "page.user.password.err":'Plwase input password',  
  "page.user.oldpassword":'Old password',
  "page.user.oldpassword.err":'Please input ols passwor',
  "page.user.newPassword":'New password',
  "page.user.newPassword.err":'Please input new password',
  "page.user.newPassword2":'Re-enter new password', 
  "page.user.password.err.length":'Please input at least 6 digit password', 
  "page.user.password.err.notmatch":'Please input the same password', 
  "page.user.vcode":'Verification code',
  "page.user.vcode.send":'Send Code',
  "page.user.vcode.send.success":'Sent Verification code success!',
  "page.user.vcode.send.err":'Sent Verification code error:',
  "page.user.vcode.err":'Please input verification code',

  "page.changepassword.submit.success":'Update password successfully, please re-login',
  "page.changepassword.submit.checkerr":'Please input all fields',
  "page.changepassword.submit.err":'Update password error:',

  'page.support.email':'Your Email',
  'page.support.email.err':'Please input your email',
  'page.support.content':'Content',
  'page.support.content.err':'Please input content',
  'page.support.submit.success':'Submit successfully! We will reply you as soon as possible.',
  'page.support.submit.err':'Submit error:',

  'page.login.submit.err':'Login error:',
  'page.login.forgotpassword':'Forgot Password',
  'page.login.register':'Register',

  'page.register.term.a':'By creating an account you agree to our',
  'page.register.term.b':'Terms & Conditions.',

  'page.home':'Home',
  'page.pricing':'Pricing',
  'page.account':'Account',
};
