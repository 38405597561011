import React, { Component } from 'react';
import { openErrorBox,ModalBox, showSuccessMessage, showErrorMessage } from '../MessageBox';
import { AvForm, AvGroup, AvInput, AvFeedback,  } from 'availity-reactstrap-validation';
import AccountHeader from './AccountHeader';
import { Col, Button,  FormGroup, Label,   } from 'reactstrap';
import {POST_SUPPORT_URL,post_data_token} from '../../config/network'
import {FormattedMessage} from 'react-intl';


class Support extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            email:'',
            content:'',
         };
         this.handleChange = this.handleChange.bind(this);
         this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(event) {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleSubmit(event) {
        
        event.preventDefault();

        if(this.state.email===''){
            openErrorBox.call(this,{content:window.appLocale.messages['page.support.email.err']||'Please input email'});
            return;
        }
        if(this.state.content===''){
            openErrorBox.call(this,{content:window.appLocale.messages['page.support.content.err']||'Please input content'});
            return;
        }

        post_data_token(POST_SUPPORT_URL,{
            email:this.state.email,
            content:this.state.content,
        })
        .then(data=>{
            showSuccessMessage.call(this,window.appLocale.messages['page.support.submit.success']||'Submit successfully! We will reply you as soon as possible.')
        })
        .catch(err=>{
            showErrorMessage.call(this,window.appLocale.messages['page.user.support.submit.err']||'submit error:'+err);
        })
   
        
    }
    render() {
        return (
            <div >
                <div className="main">
                    <AccountHeader page={"Support"} title='Support' />
                    <div className="main-body">

                        <AvForm onSubmit={this.handleSubmit}>
                            
                            <AvGroup>
                                <Label for="email"><FormattedMessage id="page.support.email" defaultMessage="Your Email"/></Label>
                                <AvInput name="email" type='email' id="email" required onChange={this.handleChange}/>
                                <AvFeedback><FormattedMessage id="page.support.email.err" defaultMessage="please input your email"/></AvFeedback>
                            </AvGroup>
                            
                            <AvGroup>
                                <Label for="content"><FormattedMessage id="page.support.content" defaultMessage="Content"/></Label>
                                <AvInput name="content" type='textarea' id="content" 
                                style={{height:'200px'}}
                                required onChange={this.handleChange}/>
                                <AvFeedback><FormattedMessage id="page.support.content.err" defaultMessage="please input content"/></AvFeedback>
                            </AvGroup>
                            

                            <FormGroup row>
                                <Col sm={{ size: 2 }}>
                                    <Button color="primary" type="submit"><FormattedMessage id="page.submit" defaultMessage="Submit"/></Button>
                                </Col>
                            </FormGroup>
                        </AvForm>
                        { ModalBox.call(this)}
                    </div>
                </div>
            </div>
        );
    }
}

export default Support;