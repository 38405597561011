import React, { Component } from 'react';
import './Account.css'
import { Col, Button, FormGroup, Label, Input,  } from 'reactstrap';
import './SendSMS.css'
import AccountHeader from './AccountHeader';
import { 
    GET_APP_KEY_URL,
    GET_USER_STAT_URL,
    WALLET_BALANCE_URL,
    POST_SMS_URL,
     get_data_token, 
     post_data
    } from '../../config/network'
import { AvForm, AvGroup, AvInput, AvFeedback, } from 'availity-reactstrap-validation';
import {ModalBox,openErrorBox,openConfirmBox,openProgressBox,ProgressBox,showErrorMessage,} from '../MessageBox'
import {FormattedMessage} from 'react-intl';


class SendSMS extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sms: '',
            sms_count:0,
            sms_limit:160,
            phoneText: '',
            phones: '',
            countryCode:'US',
            phone_count: 0,
            modal: false,
            apps:[],
            appKey:'',
            appSecret:'',
            SentSMSCurMonth:0,
            SentSMSTotal:0,
            Balance:0,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeSMS = this.handleChangeSMS.bind(this);
        this.handleChangePhones = this.handleChangePhones.bind(this);
        this.handleChangeCountryCode = this.handleChangeCountryCode.bind(this);
        this.handleChangeAppKey = this.handleChangeAppKey.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.sendSMS = this.sendSMS.bind(this);
        this.sentSMSCount= 0;
        this.phoneList = [];
        this.phoneList2 = [];

        
        this.regs = {
            US: /(\D|^)(001|1)?(\d{3}\D{0,2}\d{3}\D?\d{4})(?=\D|$)/g,
            CHINA: /(\D|^)(0086|86)?(\d{3}\D?\d{4}\D?\d{4})(?=\D|$)/g,
        }

    }
    componentDidMount(){
        this.getAppKeys();
        get_data_token(WALLET_BALANCE_URL,{})
        .then(data=>{
            this.setState({
                Balance:data.balance,
            })
        })
        .catch(err=>showErrorMessage.call(this,'Get balance error:'+err))
        get_data_token(GET_USER_STAT_URL,{})
        .then(data=>{
            this.setState({
                SentSMSCurMonth:data.SentSMSCurMonth,
                SentSMSTotal:data.getSentSMSTotal,
            })
        })
        .catch(err=>showErrorMessage.call(this,'Get user statistics error:'+err));
    }
    //
    getCurCost(){
            let smsCount = this.state.SentSMSCurMonth;
            let numbers = [0,10000,100000,1000000];
            let prices =  [0.006,0.005,0.004,0.003];
            let amounts = [0,60,510,4110];
            let len = amounts.length;
            for(var i = len-1;i>=0;i--) 
                if(smsCount>=numbers[i]) break;
            return amounts[i] + (smsCount-numbers[i])*prices[i];
        }
    getCurMonthSMS(){
            let curMonthCost = this.getCurCost();
            let money = this.state.Balance + curMonthCost;
            let numbers = [0,10000,100000,1000000];
            let prices =  [0.006,0.005,0.004,0.003];
            let amounts = [0,60,510,4110];
            let len = amounts.length;
            for(var i = len-1;i>=0;i--) 
                if(money>=amounts[i]) break;
            return numbers[i] + Math.floor((money-amounts[i])/prices[i])-this.state.SentSMSCurMonth;
        }
        //
    getAppKeys(){
        get_data_token(GET_APP_KEY_URL,{})
        .then(data=>{
            data.App.map(app=>app.key=app.app_id);
            this.setState({apps:data.App,
                appKey:data.App[0].app_key,
                appSecret:data.App[0].app_secret,});
            // console.log("getAppKeys",data.App)
        })
        .catch(err=>{
            this.setState({apps:[]});
        })
    }
//
//   retSMSCOUNT(){
//     const SENT_LIMIT = 100;
//     let phones = '';
//     for(var index =0;index<SENT_LIMIT&&this.phoneList2.length>0;index++){
//         let p = this.phoneList2.shift();
//         if(phones==='') phones = p;
//         else phones +=';'+ p;
//     }
//     return index;
//    }
//
    handleSubmit(event) {
        let smscount=this.getCurMonthSMS();
        let num= this.state.sms_count*this.state.phone_count;
        // let num=this.retSMSCOUNT();
        event.preventDefault();
        if(this.state.appKey===''||this.state.appSecret===''){
            openErrorBox.call(this,{content:<FormattedMessage id="page.sendsms.err.selectappkey" defaultMessage="Please create a app key."/>});
            return;
        }
        if(this.state.sms===''){
            openErrorBox.call(this,{content:<FormattedMessage id="page.sendsms.err.SMSContent" defaultMessage="Please input SMS content."/>});
            return;
        }
        if(this.state.phone_count===0){
            openErrorBox.call(this,{content:<FormattedMessage id="page.sendsms.err.PhoneNumbers" defaultMessage="Please input phone numbers."/>});
            return;
        }
        if(smscount-num>=0)
        {
           openConfirmBox.call(this,
            {
                title:<FormattedMessage id="page.sendsms.title.SubmitSMS" defaultMessage='Submiting SMS'/>,
                content:<FormattedMessage id="page.sendsms.confirminfo" 
                            defaultMessage="You will send to {count1} phone numbers, total {count2} SMS."
                            values={{
                                count1:this.state.phone_count,
                                count2:this.state.sms_count*this.state.phone_count,
                            }}
                        />,
                onOK:()=>{
                    this.phoneList2 = this.phoneList.slice(0);
                    openProgressBox.call(this,{
                        title:<FormattedMessage id="page.sendsms.title.SubmitSMS" defaultMessage='Submiting SMS'/>,
                        value:0,
                    });
                    this.sendSMS();
                }
            }
        
          );
        }
        else
        {
            //showErrorMessage.call(this,'You can only send '+smscount+' text messages. Please recharge.');
            
            if (smscount>0)
            {
                showErrorMessage.call(this,'You can only send '+smscount+' text messages. Please recharge.');
            }
            else
            {
                showErrorMessage.call(this,'Your fund is not enough, please recharge your account ');
            }
           
        }
        return;
        
    }
    getCountryCodeNumber(codeName){
        if(codeName==='US') return '+1';
        else if(codeName==='CHINA') return '0086';
        else return '';
    }
    
    sendSMS(){
        const SENT_LIMIT = 100;
        let phones = '';
        // console.log('this.phoneList2',this.phoneList2,this.phoneList2.length);
        for(var index =0;index<SENT_LIMIT&&this.phoneList2.length>0;index++){
            let p = this.phoneList2.shift();
            if(phones==='') phones = p;
            else phones +=';'+ p;
        }
        if(phones!=='')  
            this.sendSMS_i(phones,index);
    }

    sendSMS_i(phones,number){
        
        post_data(POST_SMS_URL,{
            appKey:this.state.appKey,
            appSecret:this.state.appSecret,
            sendTo:phones,
            countryCode:this.getCountryCodeNumber(this.state.countryCode),
            content:this.state.sms,
        })
        .then(data=>{
            openProgressBox.call(this,{
                title:<FormattedMessage id="page.sendsms.title.SubmitSMS" defaultMessage='Submiting SMS'/>,
                value:Math.floor((this.phoneList.length-this.phoneList2.length)*100/this.phoneList.length),
                successInfo:<FormattedMessage id="page.sendsms.progressbox.successinfo" defaultMessage='SMS Submit Successfully!'/>,
            });
            this.sendSMS();
        })
        
    }
    handleChangeSMS(event) {
        let value = event.target.value;
        let nonAscii = value.search(/[^\x00-\x7F]/);
        let SMS_LEN_LIMIT = 160;
        if(nonAscii !== -1) SMS_LEN_LIMIT = 70;
        let sms_num = Math.ceil(value.length/SMS_LEN_LIMIT);
        this.setState({ [event.target.id]: event.target.value ,
            sms_count:sms_num,
            sms_limit:SMS_LEN_LIMIT,
        });
    }
    handleChangeCountryCode(event) {
        this.setState({ [event.target.id]: event.target.value });
        this.parsePhones(this.state.phoneText,event.target.value);
    }
    handleChangeAppKey(event) {
        let vals = event.target.value.split(',');
        this.setState({ appKey: vals[0],appSecret:vals[1]});
    }
    handleChangePhones(event) {
        this.setState({ [event.target.id]: event.target.value });
        this.parsePhones(event.target.value,this.state.countryCode);
    }
    parsePhones(phoneText,countryCode){
        // console.log('parsePhone',phoneText,countryCode)
        let p = '';
        let poneList = [];
        const reg = this.regs[countryCode]
        let count = 0;
        let str = phoneText;
        let matchs = str.match(reg)
        if (matchs instanceof Array) {
            for (let p1 of matchs) {
                p1 = p1.replace(/\D/g, '');
                if(countryCode==='US' && p1.length>10) p1=p1.substr(p1.length-10);
                else if(countryCode==='CHINA' && p1.length>11) p1=p1.substr(p1.length-11);
                if(p==='') p=p1;
                else
                  p += ';'+ p1;
                  poneList.push(p1);
                ++count;
            }
        }
        this.phoneList = poneList;
        this.setState({ phones: p, phone_count: count });

    }

    getParsedDes(){
        if(this.state.phone_count>0)
            return <FormattedMessage id="page.sendsms.parsedes" 
                        defaultMessage="Parsed {count1} mobile phone numbers: {count2}"
                        values={{
                            count1:this.state.countryCode,
                            count2:this.state.phone_count,
                        }}
                    />
        if(this.state.phoneText==='')
            return '';
    }
    renderShowPhones(){
        if(this.state.phone_count===0) return null;
        return(

            <FormGroup row>
                <Label for="showphones" sm={12}>{this.getParsedDes()}</Label>
                
                {/* <Label className='show-phones'  for="showphones" sm={12}>{this.state.phones}</Label> */}
                <Col sm={12}>
                    <Input type="textarea" id="phoneText" className='show-phones'
                        value={this.state.phones} />
                </Col>

            </FormGroup>
        )
    }
    getSMSContentTip(){
        return(
            <FormattedMessage id="page.sendsms.contenttip" 
                defaultMessage="{count1} SMS, {count2} Characters ({count3}/SMS)"
                values={{
                    count1:this.state.sms_count,
                    count2:this.state.sms.length,
                    count3:this.state.sms_limit,
                }}
            />
        )
    }
        

    validatePhone(value, ctx, input, cb){
        // cancel pending 'network call'
        clearTimeout(this.timeout);
    
        // simulate network call
        this.timeout = setTimeout(() => {
          cb(this.state.phone_count>0 || value === '');
        }, 200);
    
      };


    render() {
        return (
            <div >
                <div className="main">
                    <AccountHeader page={"SendSMS"} title={<FormattedMessage id="page.sidnav.title.SendSMS" defaultMessage="Send SMS"/>} />
                    <div className="main-body">

                        <AvForm onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label for="appKey">
                                <FormattedMessage id="page.sendsms.title.selectappkey" defaultMessage="Slect a App Key to Send SMS"/>
                                </Label>
                                <Input type="select" name="appKey" id="appKey"  onChange={this.handleChangeAppKey}>
                                    {
                                        this.state.apps.map(app=>
                                            <option value={app.app_key+','+app.app_secret}>APP ID {app.app_id}</option>
                                        )
                                    }
                                </Input>
                            </FormGroup>
                            <AvGroup>
                                <Label for="sms">
                                <FormattedMessage id="page.sendsms.title.SMSContent" defaultMessage="SMS Content"/>
                                </Label>
                                <AvInput name="sms" type='textarea' id="sms" required onChange={this.handleChangeSMS}/>
                                <AvFeedback><FormattedMessage id="page.sendsms.err.SMSContent" defaultMessage="Please input SMS content."/></AvFeedback>
                                <span className='input-tip' >{this.getSMSContentTip()}</span>
                            </AvGroup>
                            

                            
                            <FormGroup>
                                <Label for="countryCode">
                                <FormattedMessage id="page.sendsms.title.CountryCode" defaultMessage="Country Code"/>
                                </Label>
                                <Input type="select" name="countryCode" id="countryCode"  onChange={this.handleChangeCountryCode}>
                                    <option value='US'>U.S.(+1)</option>
                                    <option value="CHINA">CHINA(+86)</option>
                                </Input>
                            </FormGroup>

                            <AvGroup>
                                <Label for="phoneText">
                                <FormattedMessage id="page.sendsms.title.PhoneNumbers" defaultMessage="Phone Numbers"/>
                                <br/>
                                <span className="input-tip">
                                <FormattedMessage id="page.sendsms.title.PhoneNumbers.tip" defaultMessage=" You can enter or copy any text containing phone numbers to here, and the phone numbers can be automatically extracted."/>
                                    </span>
                                    </Label>
                                <AvInput name="phoneText" type='textarea' id="phoneText" required
                                    onChange={this.handleChangePhones}
                                    validate={{async: this.validatePhone}}
                                />
                                <AvFeedback><FormattedMessage id="page.sendsms.err.PhoneNumbers" defaultMessage="Please input phone numbers."/></AvFeedback>
                            </AvGroup>

                            {this.renderShowPhones()}
                            

                            <FormGroup row>
                                <Col sm={{ size: 2 }}>
                                    <Button color="primary" type="submit"><FormattedMessage id='page.submit' defaultMessage='Submit'/></Button>
                                </Col>
                            </FormGroup>
                        </AvForm>
                        { ModalBox.call(this)}
                        { ProgressBox.call(this)}
                    </div>
                </div>
            </div>
        );
    }
}

export default SendSMS;