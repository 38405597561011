

 export const ROOT_URL = 'http://sms.unibox.com.cn/';
//export const ROOT_URL = 'https://smss.ez2gosms.us/';
// REACT_APP_API_ROOT_URL from .env
//export const ROOT_URL = process.env.REACT_APP_API_ROOT_URL; 
export const BASE_URL = ROOT_URL+'app/';

//login
export const POST_SMS_URL = ROOT_URL+'api/Message/postMessage'

export const LOGIN_REGISTER_URL = BASE_URL+'login/register'
export const LOGIN_RESETPSD_URL = BASE_URL+'login/resetPsd'
export const LOGIN_CHANGE_PASSWORD = BASE_URL+'login/changePsd'
export const LOGIN_CHECKPHONE_URL = BASE_URL+'login/checkPhone'
//export const VCODE_SEND_URL = BASE_URL+'login/VcodeSend'
export const VCODE_SEND_URL = BASE_URL+'login/sendvcode'

export const EMAIL_VERIFY_URL = BASE_URL+'login/verifyEmail'
export const LOGIN_LOGIN_URL = BASE_URL+'login/login'
export const GET_APP_KEY_URL = BASE_URL+'login/getAppKeyInfo'
export const ADD_APP_KEY_URL = BASE_URL+'login/createAppKey'
export const DEL_APP_KEY_URL = BASE_URL+'login/deleteAppKey'
export const GET_MEMBER_INFO_URL = BASE_URL+'login/getMember'
export const UPDATE_MEMBER_INFO_URL = BASE_URL+'login/updateMemberInfo'
export const WALLET_BALANCE_URL = BASE_URL+'wallet/getUserBalance'
export const PAYPAL_TOKEN_URL = BASE_URL+'paypal/token'
export const PAYPAL_CHECKOUT_URL = BASE_URL+'paypal/checkout'
export const PAYPAL_ADDWALLET_URL = BASE_URL+'wallet/addUserWalletN'

export const GET_USER_STAT_URL = BASE_URL+'state/getUserStat'
export const RECHARGE_DETAIL_URL = BASE_URL+'state/getUserRechargeDetail'
export const POST_SUPPORT_URL = BASE_URL+'support/postcontent'

export function post_data(url,data){
    // console.log('post_data:',url,data);
    let params = new FormData();
    for(let prop in data)
        params.append(prop, data[prop]);
        //console.log ("params:",params);
    let promise = new Promise((resolve,reject)=>{
        fetch(url, {
            method: 'POST',
            body:params,
          })
          .then(response =>{ return response.json()})
          .then(json=>{
                if(json.ret===1){
                    localStorage.clear();
                    reject('Please login again.');
                }
                if(json.ret!==0) 
                    reject(json.msg);
                resolve(json.data||{});
          })
          .catch((error) =>{
                reject(error);
          })
    })
    return promise;
    
}

export function get_data(url,params){
    let p = '';
    for(let prop in params){
        if(p==='') 
        p+='?';
        else 
        p+='&';
        p += prop +'='+ params[prop];
    }
    // console.log('get_data:',url+p);
    let promise = new Promise((resolve,reject)=>{
        fetch(url+p)
          .then(response =>{ return response.json()})
          .then(json=>{
                if(json.ret===1){
                    localStorage.clear();
                    reject('Please login again.');
                }
                if(json.ret!==0) 
                    reject(json.msg);
                resolve(json.data||[]);
          })
          .catch((error) =>{
                reject(error);
          })
    })
    return promise;
    
}
function checkToken(data){
    if(data==null) data={};
    data._accessToken = localStorage.getItem('accessToken');
    data._memberId = localStorage.getItem('memberId');
    if(data._accessToken==null)
    return new Promise((resolve,reject)=>{
         reject('please login again');
     })
    return null;
}
export function post_data_token(url,data){
    let ret = checkToken(data);
    if(ret!=null) return ret;
    return post_data(url,data);
}
export function get_data_token(url,data){
    let ret = checkToken(data);
    if(ret!=null) return ret;
    return get_data(url,data);
}


export function logout(){
    localStorage.clear();
}

export function login(phone,password){
    // console.log('====login===')    
    let promise = new Promise((resolve,reject)=>{ 
        post_data(LOGIN_LOGIN_URL,{
            phone:phone,
            psd:password,
        }).then(data=>{
            localStorage.setItem('accessToken',data.accessToken);
            localStorage.setItem('memberId',data.memberId);
            localStorage.setItem('isProfileCompleted',data.is_profile_completed);
            localStorage.setItem('isEmailVerified',data.is_email_verified);
            resolve(data);
        }).catch(error=>{
            localStorage.clear();
            reject(error);
        })
    })
    return promise;
}

